import React, { useState } from "react";
import {
	Popup,
	PopupSection,
	Heading,
	Button,
	Text,
} from "@flixbus/honeycomb-react";

export const ActionPopup = ({
	title,
	onAction,
	onClose,
	text,
	actionButtonText,
	isShown = true,
}) => {
	const [isOpen, setIsOpen] = useState(isShown);

	const togglePopup = () => {
		setIsOpen(!isOpen);
	};

	const handleAction = () => {
		onAction();
		togglePopup();
	};

	const handleClose = () => {
		onClose();
		togglePopup();
	};

	return (
		<>
			<Popup
				aria-labelledby="comments-popup-heading"
				active={isOpen}
				onOverlayClick={handleClose}
				data-testid="comments-popup"
			>
				<PopupSection type="title">
					<Heading
						id="comments-popup-heading"
						data-testid="comments-popup-heading"
						size={2}
						Elem="h3"
					>
						{title}
					</Heading>
				</PopupSection>

				<PopupSection type="content">
					<Text>{text}</Text>
				</PopupSection>

				<PopupSection type="actions">
					{/* close button is always visible for the popup */}
					<Button
						data-testid="action-popup-close"
						appearance="primary"
						onClick={handleClose}
					>
						Close
					</Button>
					<Button
						appearance="tertiary"
						onClick={handleAction}
						data-testid="action-popup-action-button"
					>
						{actionButtonText}
					</Button>
				</PopupSection>
			</Popup>
		</>
	);
};
