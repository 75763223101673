import React, { useEffect, useState } from "react";
// components
import { Checkbox, DataTable, DataTableHead } from "@flixbus/honeycomb-react";
import { RefundTableHeaders } from "../../../../constants";
import { RefundItemTableRow } from "../RefundItemTableRow/RefundItemTableRow";

const RefundItemsTable = ({
	orderItems,
	isDisabled,
	maxRefundAmount,
	onSelectedItemsChange = () => {},
}) => {
	const [selectedItems, setSelectedItems] = useState([]);
	const [areAllSelected, setAreAllSelected] = useState(false);
	const [refundableOrderItems, setRefundableOrderItems] = useState([]);

	useEffect(() => {
		// filter order items to get only refundable items (price > 0 and <= max refund)
		if (orderItems && orderItems.length && maxRefundAmount) {
			setRefundableOrderItems([]);
			orderItems.forEach((orderItem) => {
				if (
					orderItem &&
					orderItem.priceGross <= maxRefundAmount &&
					orderItem.priceGross > 0
				) {
					setRefundableOrderItems((prevState) => [...prevState, orderItem]);
				}
			});
		}
	}, [orderItems, maxRefundAmount]);

	useEffect(() => {
		// if the length of selected items is equal to filtered orderItems length, set all items checkbox as selected
		if (
			selectedItems.length &&
			selectedItems.length === refundableOrderItems?.length
		) {
			setAreAllSelected(true);
		} else {
			setAreAllSelected(false);
		}
		// every time selected items change, re-calculate total refund amount
		calculateTotalRefundAmount(selectedItems);
	}, [selectedItems]);

	const handleItemSelection = (item) => {
		// set selected items
		setSelectedItems((prevItems) => {
			if (prevItems.includes(item)) {
				return prevItems.filter((selectedItem) => selectedItem.id !== item.id);
			}
			return [...prevItems, item];
		});
	};

	const calculateTotalRefundAmount = (items) => {
		if (items && items.length === 0) {
			onSelectedItemsChange(0, null);
		} else {
			// calculate total refund amount of float prices
			const sum = items.reduce((acc, item) => acc + item.priceGross, 0);
			onSelectedItemsChange(sum, items);
		}
	};

	const selectAll = (e) => {
		setAreAllSelected((prevChecked) => !prevChecked);

		// it's either select all (refundableOrderItems) or deselect all ([])
		const updatedItems =
			selectedItems.length < refundableOrderItems.length
				? refundableOrderItems
				: [];

		setSelectedItems(updatedItems);
		// re-calculate total refund amount
		calculateTotalRefundAmount(updatedItems);
	};

	return (
		// if refundableOrderItems is empty, return N/A
		!!refundableOrderItems && refundableOrderItems.length ? (
			<div data-testid="refund-items-table">
				<DataTable
					scrollable
					headers={
						<DataTableHead
							data-testid="refund-items-table-head"
							checkbox={
								<Checkbox
									small
									aria-label="Select All"
									id="select-all"
									value="select-all"
									data-testid="select-all"
									onChange={selectAll}
									checked={isDisabled ? false : areAllSelected}
									disabled={isDisabled}
								/>
							}
						>
							{RefundTableHeaders}
						</DataTableHead>
					}
				>
					{/* show refundable items only, with price < max refund amount */}
					{refundableOrderItems.map((orderItem, index) => (
						<RefundItemTableRow
							key={index}
							orderItem={orderItem}
							isDisabledItem={isDisabled}
							onItemSelection={(item) => {
								handleItemSelection(item);
							}}
							isSelectedItem={selectedItems.includes(orderItem)}
						/>
					))}
				</DataTable>
			</div>
		) : (
			<p>Order Items are N/A</p>
		)
	);
};

export default RefundItemsTable;
