// type can take the following values: "payment", "voucher", "orders"
import React from "react";
// components
import { Heading } from "@flixbus/honeycomb-react";
import {
	Icon,
	IconCash,
	IconVoucher,
	IconPartner,
} from "@flixbus/honeycomb-icons-react";
// styles
import "./SectionHeader.scss";

export const SectionHeader = ({ type, headerText }) => {
	return (
		<Heading sectionHeader size={3} extraClasses="SectionHeader">
			<span className="SectionHeader__name">
				{type === "payment" && (
					<Icon InlineIcon={IconCash} size={5} extraClasses="HeaderIcon" />
				)}
				{type === "voucher" && (
					<Icon InlineIcon={IconVoucher} size={5} extraClasses="HeaderIcon" />
				)}
				{type === "orders" && (
					<Icon InlineIcon={IconPartner} size={5} extraClasses="HeaderIcon" />
				)}
				{headerText}
			</span>
		</Heading>
	);
};
