import { useState, useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
// components
import {
	Spinner,
	PageContainer,
	Grid,
	Heading,
	GridCol,
} from "@flixbus/honeycomb-react";
import {
	OrderItemsTable,
	PaymentBasicInfo,
	OrderDetailsInfo,
	PromoCodesTable,
	EmailDeliveryInfo,
	OrderHistory,
	OrderActionButtons,
} from "../components";
import { NoResultsMessage } from "../../../components/NoResultsMessage/NoResultsMessage";
import NotFound from "../../notFound/NotFound";
// constants
import * as CONSTANTS from "../../../constants";
import { USER_ROLES } from "../../../auth/UserRoles";
// utils
import { loginRequest } from "../../../authConfig";
import { getOrderDetails } from "../Orders.service";
import AccessGuard from "../../../auth/AccessGuard";
import { authenticate, createUserData, isLocalhost } from "../../../utils";
// styles
import "./OrderDetails.scss";
import { useErrorStatus } from "../../../utils/hooks";
import { ActionPopup } from "../../../components";

export const OrderDetails = () => {
	const isAuthenticated = useIsAuthenticated();
	const { id: orderId } = useParams();
	const { instance, accounts } = useMsal();
	const request = {
		...loginRequest,
		account: accounts[0],
	};
	const [order, setOrder] = useState({});
	const [token, setToken] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isNotFound, setIsNotFound] = useState(false);
	const [errorStatus, setErrorStatus] = useState(null);

	useErrorStatus(errorStatus);

	const getOrder = (token, orderId) => {
		const userInfo = createUserData(accounts[0]);
		getOrderDetails(userInfo, token, orderId).then((response) => {
			if (response && response.data) {
				setOrder(response.data);
				setIsNotFound(false);
				setErrorStatus(null);
			} else {
				const responseErrorStatus = response?.response?.status;
				if (response && responseErrorStatus === 404) {
					setIsNotFound(true);
					setErrorStatus(null);
				} else {
					// when error occurs
					setErrorStatus(responseErrorStatus);
					setIsNotFound(false);
					// todo: show error message
				}
			}
			setIsLoading(false);
		});
	};

	useEffect(() => {
		// Set the tab title dynamically
		document.title = `${orderId}`;
		// Clean up by resetting the tab title when component unmounts
		return () => {
			document.title = "BirdView";
		};
	}, [orderId]);

	// initial token acquisition
	useEffect(() => {
		if (orderId !== undefined) {
			setIsLoading(true);
			(async () => {
				try {
					if (isAuthenticated && !!accounts.length) {
						await instance
							.acquireTokenSilent(request)
							.then((response) => {
								if (response) {
									setToken(response.idToken);
								} else {
									console.log("no token");
								}
							})
							.catch((e) => {
								console.error(e);
								localStorage.clear();
								// redirect to login
								instance.loginRedirect(loginRequest);
							});
					} else {
						if (isLocalhost()) {
							// for local env
							console.log("Not authenticated on local host");
							setOrder(CONSTANTS.OrdersMockup.content[0]);
						} else {
							authenticate(instance);
						}
						setIsLoading(false);
					}
				} catch (e) {
					console.error(e);
				}
			})();
		}
	}, []);

	useEffect(() => {
		if (token && token !== undefined) {
			setIsLoading(true);
			getOrder(token, orderId);
		}
	}, token);

	return (
		<PageContainer>
			{!isNotFound ? (
				<div className="mb-4">
					<div className="OrderDetailsHeading">
						<Grid>
							<GridCol extraClasses="OrderIdHeader" size={12} md={6}>
								<Heading size={1} elem="h1">
									Order #{orderId}
								</Heading>
							</GridCol>
							{/* action buttons */}
							<GridCol extraClasses="OrderActionButtons" size={12} md={6}>
								{/* order can be modified only by super user and standard user */}
								<AccessGuard
									allowedAzureGroups={[
										USER_ROLES.SUPER_USER,
										USER_ROLES.USER,
										USER_ROLES.REFUND_USER,
									]}
									showMessage={false}
								>
									<OrderActionButtons order={order} />
								</AccessGuard>
							</GridCol>
						</Grid>
					</div>
					<hr />
					{/* main order info */}
					{!isLoading ? (
						orderId && order ? (
							<>
								<OrderDetailsInfo order={order} />
								<Grid>
									<EmailDeliveryInfo
										deliveries={order?.deliveries}
										messageServiceStatus={order?.messageServiceStatus}
									/>
									<OrderHistory orderHistory={order?.orderHistory} />
								</Grid>
								<PaymentBasicInfo order={order} />
								{order?.vouchers && order?.vouchers.length ? (
									<PromoCodesTable vouchers={order?.vouchers} />
								) : null}
								<OrderItemsTable
									order={order}
									onCommentsUpdate={() => getOrder(token, orderId)}
								/>
							</>
						) : (
							<NoResultsMessage />
						)
					) : (
						<div
							className="Loader"
							aria-live="polite"
							aria-busy={isLoading}
							data-testid="loader"
						>
							<Spinner />
						</div>
					)}
				</div>
			) : (
				<NotFound />
			)}
		</PageContainer>
	);
};
