export const RefundReasons = [
	"Bus or train cancellation",
	"Passenger right bus delay",
	"Passenger right train delay",
	"Overbooking",
	"T&C refund",
	"Breakdown",
	"Accident",
	"Cancellation/processing fee",
	"Missed Auto-IC",
	"Wrong/missing information for the customer",
	"Seat reservation",
	"Double booking",
	"Early departure/misplaced bus stop",
];

export const SuccessAlertTypes = {
	auto_message: "auto_message",
	reminder: "reminder",
};

export const RefundNotifications = {
	[SuccessAlertTypes.auto_message]:
		"A comment was automatically added on the Order Details page, next to the refunded order items. Please refresh the Order Details page to see the changes.",
	[SuccessAlertTypes.reminder]:
		"Remember to add a comment on the Order Details page.",
};

export const DefaultError = {
	message: "Something went wrong. Please try again later.",
	code: "Uknown Error",
};

export const RefundWarningMessage = (
	<span>
		You are about to request a refund for a transaction that took place{" "}
		<b>less than 24 hours ago</b>: it is highly recommended to wait at least 24
		hours after the payment.
	</span>
);
