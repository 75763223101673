// Function to anonymize the data
// type: phone, email, name
export const anonymizePersonalData = (data, type) => {
	if (!data) return "***";
	const value = data.toString();
	if (value.length < 4 || type === "none") return "***";

	// based on type of data, apply the anonymization
	switch (type) {
		case "phone":
			return value.slice(0, -4) + "****";
		case "email":
			return value.replace(/(.{3})(.*)(@.*)/, "$1***$3");
		case "name":
			// relace all characters except the first one with *
			const firstLetter = value.charAt(0);
			const replacedName =
				firstLetter + "***" + value.slice(value.length - 2, -1);
			return replacedName;
		default:
			return "***";
	}
};
